import useParallax from 'hooks/useParallax';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import background from 'assets/icons/background.svg';
import { device } from 'styles/device';

const variants = {
  hidden: {
    y: 40,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 2,
      duration: 1,
    },
  },
};

export const ParallaxBackground = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  background: url(${background}) no-repeat;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -9999;
  background-size: 321px;
  background-position-y: 50%;
  background-position-x: calc(50% - 130px);

  @media ${device.laptop} {
    background-size: auto;
    background-position-x: calc(50% - 200px);
  }
`;

const ParallaxBox = ({ children, offset = 300 }) => {
  const { isActiveParallax } = useParallax(offset);

  return (
    <ParallaxBackground
      initial="hidden"
      animate={isActiveParallax ? 'show' : 'hidden'}
      exit="hidden"
      variants={variants}
    >
      {children}
    </ParallaxBackground>
  );
};

ParallaxBox.propTypes = {
  children: PropTypes.element.isRequired,
  offset: PropTypes.number,
};

export default ParallaxBox;
