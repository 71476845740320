import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

export const IntersectionContext = React.createContext({ inView: true });

export const IntersectionObserver = ({
  children,
  triggerOnce = true, // if value set to false - observed element will reappear every time it shows up on the screen
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce,
  });

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={ref}>{children}</div>
    </IntersectionContext.Provider>
  );
};

export function useIntersection() {
  const context = useContext(IntersectionContext);

  // temporary disable
  // if (context === undefined) {
  //   throw new Error('useIntersection must be used within a IntersectionObserver');
  // }
  return context;
}

IntersectionObserver.propTypes = {
  children: PropTypes.element.isRequired,
  triggerOnce: PropTypes.bool,
};
